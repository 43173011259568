/**
 * Initialise workers below with a <Input, Output> type.
 *
 * Workers will be initialised when this file is imported and stay alive for the duration of the app session.
 *
 * if you terminate one of these workers in code, you will explicitly need to re-import this file or run code like the below to re-initialise the worker
 */
import { Email } from 'postal-mime';

import createWorker from './createWorker';

type ResizeImageMessage = { uint8Array: Uint8Array; maxWidth: number; maxHeight: number };

type ParseEmailContentMessage = { rawEmail: string };

export default {
  resizeImage: createWorker<ResizeImageMessage, string>(
    new Worker(new URL('./scripts/resize-image.worker.js', import.meta.url), { type: 'module' })
  ),
  parseEmailContent: createWorker<ParseEmailContentMessage, Email>(
    new Worker(new URL('./scripts/parse-email-content.worker.js', import.meta.url), { type: 'module' })
  )
};
