import { useCallback, useEffect, useRef, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import { useVirtualizer } from '@tanstack/react-virtual';
import cx from 'classnames';
import { stringifyUrl } from 'query-string';

import { TContact, TUserJoinedWithConnect } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useOverlayContext } from 'lib/common/contexts/OverlayContext';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Loader from 'lib/common/components/Loader';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';

import { DIRECTORY_TAB } from '../../../constants/directoryTabs';
import { TContactListData } from '../../../hooks/usePaginatedLoad';
import { TInternalContacts } from '../../../types';
import Contact, { TVirtualizedProps } from '../../Contact/Contact';
import DirectoryPlaceHolder from './DirectoryPlaceHolder';
import { formatName, transformUser } from './utils';

import styles from './contact-list.module.scss';

type TPaginatedAgentResponse = { data: { users: TUserJoinedWithConnect[]; count: number; totalPages: number } };

// need to be mindful of the numbers used here bad configurations can cause render issues
const DATABASE_PAGE_SIZE = 30;
const OVERSCAN_THRESHOLD = DATABASE_PAGE_SIZE - 10;
const REFETCH_MS = 5000;

const [desktopRowHeight, softphoneRowHeight, gapSpace] = [40, 30, 20];

const getItemsPageNumber = (contactArrayIndex: number) => {
  //arrays are 0 indexed whereas pages are 1 indexed
  // 0th item is on the 1st page
  return Math.ceil((contactArrayIndex + 1) / DATABASE_PAGE_SIZE);
};

// initial virtualizer approach using below,
// would highly recommend looking at this as a base but starting from scratch if creating a generic component
// https://tanstack.com/virtual/latest/docs/api/virtualizer
// https://github.com/iammarmirza/tanstack-virtualized-list/blob/main/app/page.tsx
const AgentContactList = ({
  searchTerm,
  selectedTab,
  isAdmin,
  isTransfer,
  onCreateOrUpdateClick,
  onClearSearchTerm,
  transferringToId,
  transferDisabled,
  onDeleteContact,
  onTransfer
}: {
  searchTerm: string;
  selectedTab: DIRECTORY_TAB;
  isAdmin: boolean;
  isTransfer: boolean;
  onCreateOrUpdateClick?: (contact?: TInternalContacts) => void;
  onClearSearchTerm: () => void;
  transferringToId?: string;
  onDeleteContact?: () => void;
  transferDisabled?: boolean;
  onTransfer: (contact: TContactListData) => Promise<void>;
}) => {
  const [loading, setLoading] = useState(true);

  const {
    actions: { makeOutboundCall }
  } = useContactContext();
  const { isSoftphone } = useLayout();

  const { closeOverlay } = useOverlayContext();

  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const queryClient = useQueryClient();

  const errorMessage = `Something went wrong and we couldn't fetch agent information. Please try again.`;

  const fetchData = async ({ pageParam }: { pageParam?: number | string }) => {
    const queryUrl = stringifyUrl({
      url: `${config.AGENT_SERVICE_V2_URL}/agent/users`,
      query: {
        search: searchTerm,
        withConnectInformation: true,
        page: pageParam,
        pageSize: DATABASE_PAGE_SIZE
      }
    });

    try {
      const res = await (await fetch_(queryUrl)).json();
      const {
        data: { users, count, totalPages }
      }: TPaginatedAgentResponse = res;

      const transformedUsers = users?.map(transformUser) || [];
      logger.info(LogEvents.CONTACT.LOAD.SUCCESS, {
        searchTerm,
        selectedTab,
        numberReturned: count
      });

      setLoading(false);
      return { data: transformedUsers, totalCount: count, totalPages, pageParam: pageParam as number };
    } catch (e: any) {
      requestErrorToast({ errorMessage, errorReference: e.requestId });

      logger.error(LogEvents.CONTACT.LOAD.FAIL, { error: e, searchTerm, selectedTab });
      return { data: [], totalCount: 0, totalPages: 0, pageParam: 0 };
    }
  };

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, refetch } = useInfiniteQuery('agents', fetchData, {
    getNextPageParam: ({ totalPages, pageParam = 1 }) => {
      if (totalPages === 0) {
        return undefined;
      }
      return totalPages > pageParam ? pageParam + 1 : undefined;
    }
  });

  const parentRef = useRef(null);
  const contacts = data?.pages.flatMap((page) => page.data.flat()) || [];
  const numPages = data?.pages[0]?.totalPages || 0;
  const totalSize = data?.pages[0]?.totalCount || 0;

  const rowVirtualizer = useVirtualizer({
    //if there is a next page increase size by 1 so that the loader can be displayed/fetching can be triggered
    count: hasNextPage ? contacts.length + 1 : totalSize,
    getScrollElement: useCallback(() => parentRef.current, []),
    estimateSize: useCallback(() => (isSoftphone ? softphoneRowHeight : desktopRowHeight), [isSoftphone]),
    gap: gapSpace,
    overscan: OVERSCAN_THRESHOLD
  });

  // determines if we need to load the next page of data due to it scrolling into the page, or the page has grown
  useEffect(() => {
    const [lastItem] = [...rowVirtualizer.getVirtualItems()].reverse();

    if (!lastItem) {
      return;
    }

    if (lastItem.index >= contacts.length - 1 && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [hasNextPage, fetchNextPage, contacts.length, isFetchingNextPage, rowVirtualizer.getVirtualItems()]);

  //invalidate the data source if the number of pages changes from the serverside
  useEffect(() => {
    setLoading(true);
    console.info('invalidate agents');
    queryClient.invalidateQueries('agents');
  }, [searchTerm, numPages]);

  //when the refetch changes remove the old interval and add the new one
  useEffect(() => {
    const refetchInterval = setInterval(() => {
      if (rowVirtualizer.isScrolling) {
        return;
      }
      const renderedRows = rowVirtualizer.getVirtualItems();
      const [first, last] = [renderedRows[0], renderedRows.slice(-1)[0]];

      const { start, end } = { start: getItemsPageNumber(first.index), end: getItemsPageNumber(last.index) };

      refetch({
        refetchPage: (page, index) => {
          //pages are 0 indexed in react query
          return index + 1 >= start && index + 1 <= end;
        }
      });
    }, REFETCH_MS);

    return () => clearInterval(refetchInterval);
  }, [refetch]);

  const onCallClick = async (row: TContact) => {
    if (!('phoneNumber' in row)) {
      return;
    }

    await makeOutboundCall(row.phoneNumber);
    closeOverlay();
  };

  const contactElements = rowVirtualizer.getVirtualItems().map((virtualRow) => {
    const isLoaderRow = virtualRow.index > contacts.length - 1;
    const contact = contacts[virtualRow.index];
    if (!contact) {
      if (isLoaderRow && hasNextPage) {
        return (
          <Loader
            key={'virtual-list-loader'}
            className={styles['agent-contact-list__container__internal__loader']}
            minimised
            small
          />
        );
      }
      return null;
    }
    const fullName = formatName(contact?.firstName, contact?.lastName);
    //Currently never showing drop cap may add back latter
    // const prevName = formatName(array[virtualRow.index - 1]?.firstName, array[virtualRow.index - 1]?.lastName);
    // const showDropCap = !virtualRow.index || prevName[0]?.toLowerCase() !== fullName[0]?.toLowerCase();
    const virtualListProps: TVirtualizedProps = {
      rootDivProps: {
        'data-index': virtualRow.index,
        ref: rowVirtualizer.measureElement,
        style: {
          position: 'absolute',
          transform: `translateY(${virtualRow.start}px)`
        }
      }
    };

    return (
      <Contact
        key={`virtual-contact-${virtualRow.index}`}
        contact={contact}
        showDropCap={false}
        onCallClick={onCallClick}
        transferDisabled={transferDisabled}
        isTransfer={isTransfer}
        searchTerm={searchTerm}
        isSoftphone={isSoftphone}
        fullName={fullName}
        onTransfer={onTransfer}
        isAdmin={isAdmin}
        onCreateOrUpdateClick={onCreateOrUpdateClick}
        onDeleteContact={onDeleteContact}
        transferringToId={transferringToId}
        virtualizedListProps={virtualListProps}
      />
    );
  });

  return (
    <>
      <DirectoryPlaceHolder
        isAdmin={isAdmin}
        selectedTab={selectedTab}
        searchTerm={searchTerm}
        onClearSearchTerm={onClearSearchTerm}
        loading={loading}
        numberOfContacts={contacts.length}
        onCreateOrUpdateClick={onCreateOrUpdateClick}
      />
      {/* The scrollable element for your list */}
      {Boolean(contacts.length) && (
        <div
          role="tabpanel"
          ref={parentRef}
          className={cx(styles['agent-contact-list__container'], {
            [styles['agent-contact-list__container--softphone']]: isSoftphone
          })}
        >
          {/* virtual view*/}
          <div
            className={cx(styles['agent-contact-list__container__internal'], {
              [styles['agent-contact-list__container--softphone__internal']]: isSoftphone
            })}
            style={{
              height: rowVirtualizer.getTotalSize()
            }}
          >
            {contactElements}
          </div>
        </div>
      )}
    </>
  );
};

export default AgentContactList;
