import { ReactComponent as TeamsLogo } from 'assets/vectors/teams.svg';
import cx from 'classnames';
import _startCase from 'lodash.startcase';

import Icon from 'lib/common/components/Icon';

import { ICON_MAP } from '../../../../constants/numberTypes';
import { TContactListData } from '../../../../hooks/usePaginatedLoad';

import styles from './contact-directory-icon.module.scss';

export default function ContactDirectoryIcon({
  contact,
  isSoftphone,
  isTeamsContact
}: {
  contact: TContactListData;
  isSoftphone?: boolean;
  isTeamsContact: boolean;
}) {
  const { type } = contact;

  if (!isTeamsContact) {
    return (
      <Icon
        title={_startCase(type)}
        icon={ICON_MAP[type]}
        size={isSoftphone ? 12 : 13}
        color={'darkGrey'}
        tooltipPlacement="top"
        tooltipArrow
      />
    );
  }

  return (
    <span
      className={cx(styles['contact-directory-icon__icon'], {
        [styles['contact-directory-icon__icon--softphone']]: isSoftphone
      })}
    >
      <TeamsLogo title="Microsoft Teams" />
    </span>
  );
}
