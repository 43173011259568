import { useState } from 'react';

import { match } from 'ts-pattern';

import { AuthStages, AuthState, MfaDestination, MfaDestinations } from '../types/AuthState';
import { useHandleAuthStep } from './useHandleAuthStep';

export const useMfaFlow = ({
  setInvalidCode,
  setAuthState,
  setError,
  authState
}: {
  setInvalidCode: (invalid: boolean) => void;
  setError: (error: boolean) => void;
  authState: AuthState;
  setAuthState: (state: AuthState) => void;
}) => {
  const [mfaDestinations, setMfaDestinations] = useState<{
    obfuscatedEmail: string | undefined;
    obfuscatedPhone: string | undefined;
  } | null>(null);

  const { handleAuthStep } = useHandleAuthStep({ setMfaDestinations, setInvalidCode, setError });

  const selectMfaDestination = async (mfaSelection: MfaDestination, existingState?: AuthState) => {
    const stateToUse = existingState || authState;
    const newState = await handleAuthStep(
      match({ mfaSelection, authState: stateToUse })
        .with(
          { authState: { stage: AuthStages.mfa_select }, mfaSelection: MfaDestinations.email },
          ({ mfaSelection, authState: { obfuscatedEmail, authResponse } }) => ({
            authResponse,
            stage: AuthStages.mfa_selected,
            mfaSelection,
            obfuscatedEmail
          })
        )
        .with(
          { authState: { stage: AuthStages.mfa_select }, mfaSelection: MfaDestinations.sms },
          ({ mfaSelection, authState: { obfuscatedPhone, authResponse } }) => ({
            authResponse,
            stage: AuthStages.mfa_selected,
            mfaSelection,
            obfuscatedPhone
          })
        )
        .otherwise(() => {
          throw `Invalid auth state: ${authState}`;
        })
    );
    setAuthState(newState);
  };

  const provideMfaCode = async (mfaCode: string) => {
    if (!('authResponse' in authState) || !('mfaSelection' in authState)) {
      throw `Invalid auth state: ${authState}`;
    }

    setInvalidCode(false);

    const newState = await handleAuthStep({ ...authState, stage: AuthStages.mfa_entered, mfaCode });
    setAuthState(newState);

    if (newState.stage === 'mfa_entry') {
      setInvalidCode(true);
      throw 'Invalid code';
    }
  };

  return {
    mfaDestinations,
    setMfaDestinations,
    selectMfaDestination,
    provideMfaCode,
    mfaSelection: 'mfaSelection' in authState ? authState.mfaSelection : undefined
  };
};
