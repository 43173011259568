import Select, { Props } from 'react-select';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Label from '../Label';
import { ClearIndicator, DropdownIndicator, LoadingIndicator } from './components';
import { getDefaultStyles } from './styles';

import VARS from 'css/export-vars.module.scss';

import styles from './react-select.module.scss';

export default function ReactSelect(
  props: {
    small?: boolean;
    label?: string;
  } & Props
) {
  const { isSoftphone } = useLayout();

  const selectElement = (
    <Select
      isSearchable
      styles={getDefaultStyles({ isSoftphone: props.small || isSoftphone, small: props.small })}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary50: VARS.secondary,
          primary75: VARS.secondary,
          primary: VARS.secondary,
          neutral5: VARS.lightGrey
        }
      })}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      aria-label={props.label || props.placeholder?.toString()}
      {...props}
      components={{ DropdownIndicator, ClearIndicator, LoadingIndicator, ...props.components }}
    />
  );

  if (props.label) {
    return (
      <Label id={props.label} text={props.label} className={styles['react-select__label']}>
        {selectElement}
      </Label>
    );
  }

  return selectElement;
}
