import _ from 'lodash';
import _omitBy from 'lodash.omitby';

import { TUserJoinedWithConnect } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import AsyncReactSelect, { AsyncReactSelectProps } from '../../ReactSelect/AsyncReactSelect';

const PAGE_SIZE = 10;

export default function AgentDropdown(props: Omit<AsyncReactSelectProps, 'loadOptions'>) {
  const { config } = useConfigContext();
  const { fetch_ } = useAuthContext();

  const fetchAgents = async (search: string, _, additional: { page: number } | undefined) => {
    const page = additional?.page || 1;

    const params = new URLSearchParams({
      search,
      page: page.toString(),
      pageSize: PAGE_SIZE.toString(),
      withConnectInformation: 'true'
    });

    const AGENT_URL = `${config.AGENT_SERVICE_V2_URL}/agent/users?${params}`;
    const {
      data: {
        users: contacts,
        count: { count }
      }
    }: { data: { users: TUserJoinedWithConnect[]; count: { count: number } } } = await (await fetch_(AGENT_URL)).json();

    const agents = contacts.map(({ user, userConnect }) => ({
      label: `${user.firstName} ${user.lastName}`,
      value: userConnect.connectUserId
    }));

    return {
      options: agents,
      hasMore: page * PAGE_SIZE < count,
      additional: {
        page: page + 1
      }
    };
  };

  return <AsyncReactSelect {...props} loadOptions={fetchAgents} />;
}
