import { useEffect } from 'react';
import { useInfiniteQuery, useQueryClient } from 'react-query';

import { stringifyUrl } from 'query-string';
import { P, match } from 'ts-pattern';

import { ContactType, TContact, TContactType, TUserJoinedWithConnect } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { DIRECTORY_TAB } from '../constants/directoryTabs';

type TPaginatedLoadParams = {
  type: DIRECTORY_TAB;
  pageSize?: number;
  search?: string;
};

function transformUser(user: TUserJoinedWithConnect): TContactListData {
  return {
    contactId: user.user.id,
    firstName: user.user.firstName,
    lastName: user.user.lastName,
    type: ContactType.AGENT,
    availability: user.user.availability,
    phoneNumber: user.user.phoneNumber,
    email: user.user.email,
    originalContact: user,
    connectUserId: user.userConnect.connectUserId
  };
}

function transformContact(contact: TContact): TContactListData {
  return {
    contactId: contact.contactId,
    firstName: contact.firstName,
    lastName: contact.lastName,
    type: contact.type,
    directoryId: 'directoryId' in contact ? contact.directoryId : void 0,
    phoneNumber: contact.phoneNumber,
    email: contact.email,
    originalContact: contact,
    jobTitle: contact.jobTitle,
    notes: 'notes' in contact ? contact.notes : void 0
  };
}

export type TContactListData = {
  contactId: string;
  firstName: string;
  lastName: string;
  type: TContactType;
  directoryId?: string;
  availability?: string;
  phoneNumber?: string;
  email?: string;
  originalContact: TContact | TUserJoinedWithConnect;
  jobTitle?: string;
  notes?: string;
  connectUserId?: string;
};

type TPaginatedLoadReturn = {
  data: TContactListData[]; // contains all loaded values
  loadNext: () => Promise<TContactListData[] | null>; // returns only newly fetched values
  exhausted: boolean;
};

/**
 * @deprecated
 * prefer using @tanstack/react-virtual
 * */
const usePaginatedLoad = ({ type, pageSize = 50, search }: TPaginatedLoadParams): TPaginatedLoadReturn => {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const queryClient = useQueryClient();

  useEffect(() => {
    queryClient.resetQueries('contacts');
  }, [type]);

  function getUrl() {
    return match(type)
      .with(DIRECTORY_TAB.AGENTS, () => `${config.AGENT_SERVICE_V2_URL}/agent/users`)
      .with(
        P.union(DIRECTORY_TAB.ORGANISATION, DIRECTORY_TAB.PERSONAL),
        () => `${config.DIRECTORY_SERVICE_URL}/user/contacts?type=${type.toLowerCase()}`
      )
      .exhaustive();
  }

  const fetchData = async ({ pageParam }: { pageParam?: number | string }) => {
    function getQuery() {
      return match(type)
        .with(DIRECTORY_TAB.AGENTS, () => ({
          search,
          withConnectInformation: true,
          page: pageParam,
          pageSize
        }))
        .with(P.union(DIRECTORY_TAB.ORGANISATION, DIRECTORY_TAB.PERSONAL), () => ({
          limit: pageSize,
          nextToken: pageParam,
          filter: search
        }))
        .exhaustive();
    }

    const queryUrl = stringifyUrl({ url: getUrl(), query: getQuery() });

    const res = await (await fetch_(queryUrl)).json();

    if (type === DIRECTORY_TAB.AGENTS) {
      const {
        data: { users, count }
      }: { data: { users: TUserJoinedWithConnect[]; count: number } } = res;

      const transformedUsers = users?.map(transformUser) || [];

      return { data: transformedUsers, totalCount: count, pageParam: pageParam as number };
    }

    const {
      data: { nextToken: newNextToken, records: newData }
    }: { data: { nextToken?: string; records: TContact[] } } = res;

    const transformedContacts = newData?.map(transformContact) || [];

    return { data: transformedContacts, newNextToken };
  };

  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery('contacts', fetchData, {
    getNextPageParam: (lastPage) => {
      if (type === DIRECTORY_TAB.AGENTS) {
        const currentPage = lastPage?.pageParam || 1;

        if (lastPage.totalCount === 0) {
          return undefined;
        }

        const count = currentPage * pageSize;

        return count >= lastPage.totalCount! ? undefined : currentPage + 1;
      }

      return lastPage.newNextToken;
    }
  });

  return {
    data: data?.pages.flatMap((page) => page.data.flat()) || [],
    loadNext: async () => {
      const latestPage = await fetchNextPage();

      return latestPage?.data?.pages?.slice(-1)?.[0].data || [];
    },
    exhausted: !Boolean(hasNextPage)
  };
};

export default usePaginatedLoad;
