import { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { TContact, TUserJoinedWithConnect } from '@cloud-wave/neon-common-lib';
import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useOverlayContext } from 'lib/common/contexts/OverlayContext';

import useTimeout from 'lib/common/hooks/useTimeout';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import quickConnectTypes from 'lib/common/constants/quickConnectTypes';

import getTransferEndpoint from 'lib/common/api/getTransferEndpoint';

import { TContactListData } from '../../../hooks/usePaginatedLoad';

const MODAL_BACKDROP_TIMEOUT = 500;

const useTransfers = ({ closeModal, isSoftphone }: { closeModal?: () => void; isSoftphone?: boolean }) => {
  const [transferringToId, setTransferringToId] = useState<string | undefined>(void 0);
  const { config } = useConfigContext();
  const { fetch_ } = useAuthContext();

  const { setTimeoutRef } = useTimeout();

  const {
    state: { tasks, selectedTaskId },
    actions: { onTransfer: onTransferToPhoneNumber, onTransferToQueueOrAgent }
  } = useContactContext();

  const { closeOverlay } = useOverlayContext();

  const connectedTask = tasks.find(
    (task) => task.taskId === selectedTaskId && task.status === CONTACT_STATES.CONNECTED
  );

  const history = useHistory();
  const pushToTask = history.push;

  const onAgentTransfer = async (agentId: string) => {
    if (!connectedTask?.taskId) {
      return;
    }

    const identifier = agentId.split('_')[1];

    const endpointARN = await getTransferEndpoint({
      fetch_,
      config,
      type: quickConnectTypes.AGENT,
      identifier: identifier
    });

    if (!endpointARN) {
      return setTransferringToId(void 0);
    }

    const endPoint = {
      agentLogin: '',
      endpointARN,
      endpointId: endpointARN,
      name: identifier,
      phoneNumber: '',
      queue: '',
      type: connect.EndpointType.AGENT
    };

    await onTransferToQueueOrAgent(endPoint, connectedTask?.taskId);

    return onTransferSuccessful();
  };

  const onContactTransfer = async (phoneNumber: string) => {
    await onTransferToPhoneNumber(phoneNumber);

    return onTransferSuccessful();
  };

  const onTransferSuccessful = () => {
    closeModal ? closeModal() : closeOverlay();

    if (!isSoftphone) {
      pushToTask(`${PAGE_ROUTES.WORKSPACE}${connectedTask ? `?taskId=${connectedTask.taskId}` : ''}`);
    }

    // Wait for modal to close
    setTimeoutRef(() => setTransferringToId(void 0), MODAL_BACKDROP_TIMEOUT);
  };

  const onTransfer = async (contact: TContactListData) => {
    setTransferringToId(contact.contactId);

    if (contact?.connectUserId) {
      return onAgentTransfer(contact.connectUserId);
    }

    return onContactTransfer(contact.phoneNumber!);
  };

  return { onTransfer, transferringToId };
};

export default useTransfers;
