import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

import { faCopy, faFileLines } from '@fortawesome/pro-regular-svg-icons';

import { Permissions, TUserWithConnect } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import { useAgentPreferencesContext } from 'lib/common/contexts/AgentPreferenceContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';

import useVoiceTranscripts from 'lib/common/hooks/useVoiceTranscripts';

import Button from 'lib/common/components/Button';
import Modal from 'lib/common/components/Modal';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import ACWSummary from 'lib/common/components/molecules/ACW/components/ACWSummary';
import ChatMessages from 'lib/common/components/molecules/ChatMessages';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import VoiceTranscripts from 'lib/common/components/molecules/VoiceTranscripts';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';

import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import ChatTask from 'lib/common/types/ChatTask';
import TChatTask from 'lib/common/types/ChatTask';
import { TChatMessage } from 'lib/common/types/chat/ChatMessage';
import { copyMessages } from 'lib/common/utils/ACW/copyMessages';
import { getTaskTimeInfo } from 'lib/common/utils/ACW/getTaskTimeInfo';
import getScheduleCallbackDescription from 'lib/common/utils/getScheduleCallbackDescription';
import getUserName from 'lib/common/utils/getUserName';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import isVoice from 'lib/common/utils/isVoice';

import styles from './after-contact-work.module.scss';

type TAfterContactWork = Pick<
  ChatTask,
  'contact' | 'profile' | 'connectionValue' | 'taskId' | 'type' | 'connectedAtTime' | 'time' | 'nextToken' | 'status'
> & {
  messages?: TChatMessage[];
  setCreateAgentTaskOpen: (open: boolean) => void;
  setCreateTaskDefaults: (_: any) => void;
  user: TUserWithConnect;
  queueName?: string;
  queueId?: string;
};

export default function AfterContactWork(props: TAfterContactWork) {
  const { setCreateAgentTaskOpen, setCreateTaskDefaults, user, queueName, nextToken, status, ...task } = props;
  const { contact, taskId, profile, connectionValue, type, connectedAtTime, time = new Date() } = task;

  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const {
    state: { preferredDateFormat }
  } = useAgentPreferencesContext();
  const { hasPermission } = usePermissionsContext();
  const canViewPostCallTranscripts =
    hasPermission({
      type: 'tenant',
      permission: Permissions.AGENT_INTERACTION_VOICE_ANALYSIS
    }) && isVoice({ type });
  const {
    transcripts,
    loading,
    getCallTranscripts,
    nextToken: voiceNextToken
  } = useVoiceTranscripts({
    config,
    fetch_,
    contactId: taskId,
    canViewPostCallTranscripts
  });

  const canSeeTranscript = type === CONTACT_TYPES.CHAT || canViewPostCallTranscripts;

  const [transcriptionVisible, setTranscriptionVisible] = useState<boolean>(false);

  const taskTimeInfo = getTaskTimeInfo({ contact, connectedAtTime });
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const onToggleTranscriptionModal = () => {
    setTranscriptionVisible(!transcriptionVisible);
  };

  const scheduleCallback = () => {
    setCreateTaskDefaults({
      name: 'Call A Customer Back',
      description:
        type === CONTACT_TYPES.TASK
          ? ''
          : getScheduleCallbackDescription(connectionValue, profile, taskTimeInfo.startedAt, user)
    });
    setCreateAgentTaskOpen(true);
  };

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.AFTER_CALL_SCHEDULE_CALLBACK, scheduleCallback, []);

  return (
    <>
      {canSeeTranscript && (
        <Modal
          // Primary button is Close but we need to change onSave to onPrimary in the future
          onSave={onToggleTranscriptionModal}
          onClose={onToggleTranscriptionModal}
          onSecondary={() =>
            copyMessages({
              type,
              messages: type === CONTACT_TYPES.CHAT ? (props as unknown as TChatTask).messages : transcripts,
              preferredDateFormat
            })
          }
          title={type === CONTACT_TYPES.CHAT ? 'Chat History' : 'Interaction Transcript'}
          open={transcriptionVisible}
          secondaryButtonText="Copy"
          secondaryButtonIcon={faCopy}
          secondaryAsyncAction
          primaryButtonText="Close"
          small
          syncAction
        >
          {type === CONTACT_TYPES.CHAT ? (
            <ChatMessages
              showEmptyPlaceholder
              messages={(props as unknown as TChatTask).messages}
              customerName={name}
              nextToken={nextToken}
              status={status}
            />
          ) : (
            <VoiceTranscripts
              transcripts={transcripts}
              loading={loading}
              onLoadMore={getCallTranscripts}
              nextToken={voiceNextToken}
            />
          )}
        </Modal>
      )}
      <div className={styles['after-contact-work']}>
        <div className={styles['after-contact-work__info']}>
          <QueueNameAndTimer queueName={queueName} time={time} />
          <ACWSummary taskTimeInfo={taskTimeInfo} withoutAccordion />
        </div>
        <ACWOutcome className={styles['after-contact-work__outcome']} withoutAccordion />
      </div>
      <TaskFooter.ACW scheduleCallback={scheduleCallback}>
        {canSeeTranscript && (
          <Button
            data-testid={`after-contact-work-${type === CONTACT_TYPES.CHAT ? 'chat' : 'voice'}-history-btn`}
            icon={faFileLines}
            tooltip={type === CONTACT_TYPES.CHAT ? 'View chat history' : 'View interaction transcript'}
            onClick={onToggleTranscriptionModal}
            styleType="NEUTRAL"
            round
            size="small"
          />
        )}
      </TaskFooter.ACW>
    </>
  );
}
