import { useEffect, useRef, useState } from 'react';

import { TUserWithConnect } from '@cloud-wave/neon-common-lib';

import { useConfigContext } from 'lib/core/config';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

import { CURRENT_USER_FULLNAME_KEY } from '../constants';
import { AuthStages } from '../types/AuthState';

type Fetch = (url: string, options?: RequestInit) => Promise<Response>;

export const useInitializeUser = ({
  authStage,
  fetch_,
  setError
}: {
  authStage: keyof typeof AuthStages;
  isIsolatedMode: boolean;
  fetch_: Fetch;
  setError: (error: boolean) => void;
  signOut: (signOutType: string) => void;
}) => {
  const { config } = useConfigContext();
  const [user, setUser] = useState<TUserWithConnect | null>(null);

  // Allows callbacks to get the latest user to avoid stale references, eg. in connect handlers
  const userRef = useRef(user);
  const getUser = () => userRef?.current;

  const initializeUser = async () => {
    const AGENT_URL = `${config.AGENT_SERVICE_V2_URL}/agent/user`;

    try {
      const response = await fetch_(AGENT_URL);
      const { data }: { data: TUserWithConnect } = await response.json();

      if (!data) {
        throw new Error('No user data found');
      }

      setUser(data);

      sessionStorage.setItem('user_id', data.id);
      sessionStorage.setItem('email', data.email);
      sessionStorage.setItem(CURRENT_USER_FULLNAME_KEY, `${data.firstName} ${data.lastName}`);
      logger.info(LogEvents.AUTH.INITIALIZE_USER.SUCCESS);
    } catch (error) {
      logger.info(LogEvents.AUTH.INITIALIZE_USER.FAIL, { error });
      setError(true);
    }
  };

  useEffect(() => {
    if (authStage.includes('complete')) {
      initializeUser();
    }
  }, [authStage]);

  useEffect(() => {
    userRef.current = user;
  }, [user, authStage]);

  return { user, getUser };
};
